import { BASE_ROUTES } from "../const";
import { Atom, Mod } from ":mods";

import { NotFoundWithRedirect } from "../components";
import { actions, hooks } from "../store";
import { Signup as SignupLayout } from "../../layouts";
import { SignupViewProps } from "../model";

export function Signup() {
  const $: SignupViewProps["$"] = {
    actions: {
      ...actions,
      ...Mod.Account.actions,
    },
    hooks: {
      ...hooks,
    },
  };
  return (
    <Atom.Route.Guarded
      base={BASE_ROUTES.signup}
      errorElement={() => (
        <NotFoundWithRedirect path={Mod.Signup.CONST.ROUTES.TOC} base={BASE_ROUTES.signup} duration={3000} />
      )}
      layout={() => (
        <SignupLayout
          topNav={{
            class: "nav-top-signup",
            // logoClass: "self-center !w-full ",
            logoHref: "/",
            base: BASE_ROUTES.signup,
            items: [],
          }}
        />
      )}
      guard={{
        title: "Museum Training Signup",
        steps: [Mod.Account.guard.checkUserToken, Mod.Account.guard.checkUserEvents],
      }}
      events={{
        onGuardSuccess: (trigger, {}) => {
          const token_type = $.actions.getTokenType();
          const courses = $.actions.getEventsDetails().courses || {};
          // console.log("signup success :: ", token_type);
          if (
            token_type !== Mod.Account.CONST.TOKEN_TYPE.tutor &&
            token_type !== Mod.Account.CONST.TOKEN_TYPE.student
          ) {
            trigger.navigate({ base: BASE_ROUTES.auth });
          } else if (token_type === Mod.Account.CONST.TOKEN_TYPE.student && courses["1"].is_registered) {
            trigger.navigate({ base: BASE_ROUTES.student });
          } else if (token_type === Mod.Account.CONST.TOKEN_TYPE.tutor && courses["1"]?.is_application_submitted) {
            trigger.navigate({ base: BASE_ROUTES.tutor });
          } else {
            trigger.loadRoute();
            // maybe you'll need trigger.loadRoute(), i'll leave this to you
          }
        },
        onGuardFailure: (trigger, { stepEquals }) => {
          if (stepEquals(Mod.Account.guard.checkUserToken)) {
            trigger.navigate({ base: BASE_ROUTES.auth });
          } else {
            // maybe you'll need trigger.loadRoute(), i'll leave this to you
          }
        },
      }}
    >
      {() => (
        <>
          <Mod.Signup.SignupRoutes $={$} />
        </>
      )}
    </Atom.Route.Guarded>
  );
}
